'use client'
import { loadPreferencesState } from '@/redux/features/displayPreferencesSlice'
import { loadViewMode, setViewMode } from '@/redux/features/view-mode/viewModeSlice'
import { useAppDispatch } from '@/redux/hooks'
import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

const StoreLoader = ({ children }: { children: any }) => {
    const dispatch = useAppDispatch()
    const searchParams = useSearchParams()!
    const sharing = searchParams.get('sharing')
    const sharingBy = searchParams.get('sharingby')

    useEffect(() => {
        console.log("initializing store...")
        if (sharing && sharingBy) {
            console.log("sharing set")
            dispatch(setViewMode({ mode: 'share', shareLinkUrlInfo: { sharingId: sharing, sharingBy: sharingBy } }))
        } else {
            dispatch(loadViewMode())
        }
        dispatch(loadPreferencesState())
    }, [dispatch])
    return children
}

export default StoreLoader