import { DEFAULT_DISPLAY_OPTIONS, DisplayOptions, MonthLayout, isMonthLayout } from '@/types/display'
import { getForageItem } from '@planda/design-system'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import localforage from 'localforage'
import { isBoolean, isString } from 'lodash'

interface State {
    taskDisplayOptions: DisplayOptions
    monthLayout: MonthLayout
}

const initialState: State = {
    taskDisplayOptions: DEFAULT_DISPLAY_OPTIONS,
    monthLayout: 'full',
}

const MONTH_LAYOUT_FORAGE_KEY = 'display-month-layout'
export const loadPreferencesState = createAsyncThunk('yourSlice/loadTemporaryState', async () => {
    const newDisplayOptions: Record<string, any> = {}
    const taskDisplayPromises = Object.entries(DEFAULT_DISPLAY_OPTIONS).map(async ([key, value]) => {
        const isValid = typeof value === 'boolean' ? isBoolean : isString
        const storedValue = await getForageItem('task-display--' + key, isValid, value)
        newDisplayOptions[key] = storedValue
    })

    const monthLayout = await getForageItem(MONTH_LAYOUT_FORAGE_KEY, isMonthLayout, initialState.monthLayout)

    await Promise.all(taskDisplayPromises)
    return { taskDisplayOptions: newDisplayOptions, monthLayout }
})

const displayPreferencesSlice = createSlice({
    name: 'display-preferences',
    initialState,
    reducers: {
        setTemporaryState: (state, action: PayloadAction<Partial<State>>) => {
            const updates = action.payload
            Object.entries(updates).forEach(([key, value]) => {
                // @ts-expect-error
                state[key] = value
            })
        },
        editDisplayOptions: (state, action: PayloadAction<{ field: keyof DisplayOptions; value: any }>) => {
            const { field, value } = action.payload
            // @ts-expect-error
            state.taskDisplayOptions[field] = value
            localforage.setItem('task-display--' + field, value)
        },
        setMonthLayout: (state, action: PayloadAction<MonthLayout>) => {
            state.monthLayout = action.payload
            localforage.setItem(MONTH_LAYOUT_FORAGE_KEY, action.payload)
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadPreferencesState.fulfilled, (state, action: PayloadAction<Record<string, any>>) => {
            state.monthLayout = action.payload.monthLayout
            state.taskDisplayOptions = action.payload.taskDisplayOptions as DisplayOptions
        })
    },
})

export const { setTemporaryState, editDisplayOptions, setMonthLayout } = displayPreferencesSlice.actions
export default displayPreferencesSlice.reducer
