import { NewItemInfo } from '@/features/calendars/types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type Panel = 'timegrid-day' | 'daygrid-day' | 'levels'
export type WhichPanel = Panel | null

interface State {
    listTab?: 'daily' | 'main'
    panel: WhichPanel
    tutorialArrow?: string
    openForm: boolean
    defaultFormItems?: NewItemInfo
}

const initialState: State = {
    panel: null,
    openForm: false,
}

const temporaryStateSlice = createSlice({
    name: 'temporary-state',
    initialState,
    reducers: {
        setTemporaryState: (state, action: PayloadAction<Partial<State>>) => {
            const updates = action.payload
            Object.entries(updates).forEach(([key, value]) => {
                // @ts-expect-error
                state[key] = value
            })
        },
        setPanel: (state, action: PayloadAction<WhichPanel>) => {
            state.panel = action.payload
        },
        togglePanel: (state, action: PayloadAction<Panel>) => {
            state.panel = state.panel === action.payload ? null : action.payload
        },
        openItemForm(state, action: PayloadAction<NewItemInfo | void>) {
            action.payload && (state.defaultFormItems = action.payload)
            state.openForm = true
        },
        setOpenItemForm: (state, action: PayloadAction<boolean>) => {
            state.openForm = action.payload
        }
        // not necessary to save display options for next session
        // load: async function (state, action: PayloadAction<void>) {
        //     const newDisplayOptions: Record<string, any> = {}
        //     const promises = Object.entries(DEFAULT_DISPLAY_OPTIONS).map(async ([key, value]) => {
        //         const isValid = (typeof value === 'boolean') ? isBoolean : isString
        //         const storedValue = await getForageItem('task-display--' + key, isValid, value)
        //         newDisplayOptions[key] = storedValue
        //     })
        //     await Promise.all(promises)

        //     state.taskDisplayOptions = { ...newDisplayOptions } as DisplayOptions
        // }
    },
})

export const { setTemporaryState, setPanel, togglePanel, openItemForm, setOpenItemForm } = temporaryStateSlice.actions
export default temporaryStateSlice.reducer
