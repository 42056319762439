import { Friend, FriendRequest, NewProfileInfo, NewShareableLink, PublicProfile, ShareableLinkSettings } from '@/types/social'
import { apiSlice } from './apiSlice'
import { FriendList } from '@/services/social/optional'

/**
 * TODO:
 * src/hooks/useFriends
 */

type ProfileRes = { profile?: PublicProfile }
export const socialApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMobile: builder.query<{ isPaired: true }, void>({
            query: () => `mobile`,
        }),
        getFriends: builder.query<{ friends: FriendList }, void>({
            query: () => `social/friends`,
            providesTags: ['friend'],
        }),
        getFriendRequests: builder.query<{ requests: FriendRequest[] }, void>({
            query: () => `social/requests`,
        }),
        getProfile: builder.query<ProfileRes, void>({
            query: () => `social/profile`,
            providesTags: ['profile'],
        }),
        getFriendProfile: builder.query<ProfileRes, string>({
            query: (handle) => `social/profile/${handle}`,
        }),
        sendMessages: builder.mutation<void, { handles: string[]; message: string }>({
            query: ({ handles, message }) => ({
                url: `message/vanishing`,
                method: 'POST',
                body: { handles, message },
            }),
        }),
        acceptFriendRequest: builder.mutation<void, string>({
            query: (handle) => ({
                url: `social/requests/${handle}`,
                method: 'POST',
            }),
            invalidatesTags: ['friend'],
            async onQueryStarted(handle, { dispatch, queryFulfilled }) {
                const patchedResult = dispatch(
                    socialApiSlice.util.updateQueryData('getFriendRequests', undefined, (data) => {
                        return {
                            requests: data?.requests.filter((r) => r.id !== handle) || [],
                        }
                    })
                )
                const tmpFriend: Friend = {
                    handle,
                    name: '',
                    accessLevel: 0,
                    createdAt: Date.now(),
                    updatedAt: Date.now(),
                }
                const patchedResult2 = dispatch(
                    socialApiSlice.util.updateQueryData('getFriends', undefined, (data) => {
                        return {
                            friends: [...(data?.friends || []), tmpFriend],
                        }
                    })
                )
                queryFulfilled.catch(() => {
                    patchedResult.undo()
                    patchedResult2.undo()
                })
            },
        }),
        rejectFriendRequest: builder.mutation<void, string>({
            query: (handle) => ({
                url: `social/requests/${handle}`,
                method: 'DELETE',
            }),
            async onQueryStarted(handle, { dispatch, queryFulfilled }) {
                const patchedResult = dispatch(
                    socialApiSlice.util.updateQueryData('getFriendRequests', undefined, (data) => {
                        return {
                            ...data,
                            requests: data?.requests.filter((r) => r.id !== handle) || [],
                        }
                    })
                )
                const tmpFriend: Friend = {
                    handle,
                    name: '',
                    accessLevel: 0,
                    createdAt: Date.now(),
                    updatedAt: Date.now(),
                }
                const patchedResult2 = dispatch(
                    socialApiSlice.util.updateQueryData('getFriends', undefined, (data) => {
                        return {
                            friends: [...(data?.friends || []), tmpFriend],
                        }
                    })
                )
                queryFulfilled.catch(() => {
                    patchedResult.undo()
                    patchedResult2.undo()
                })
            },
        }),
        putProfile: builder.mutation<void, PublicProfile | NewProfileInfo>({
            query: (profile) => ({
                url: `social/profile`,
                method: 'PUT',
                body: profile,
            }),
            invalidatesTags: ['profile'],
            async onQueryStarted(profile, { dispatch, queryFulfilled }) {
                const patchedResult = dispatch(
                    socialApiSlice.util.updateQueryData('getProfile', undefined, () => {
                        return { profile: { id: 'PROFILE', ...profile } }
                    })
                )
                queryFulfilled.catch(patchedResult.undo)
            },
        }),
        getShareLinkSettings: builder.query<ShareableLinkSettings, string>({
            query: (sharing: string) => `social/share/link/${sharing}`,
        }),
        getShareLinks: builder.query<{ shareLinks?: ShareableLinkSettings[] }, void>({
            query: () => `social/share/links`,
            providesTags: (result) =>
                result?.shareLinks?.map(({ id }) => ({
                    type: 'shareLink',
                    id,
                })) || [],
        }),
        putShareLink: builder.mutation<void, NewShareableLink>({
            query: (link) => ({
                url: `social/share/link/${link.id || ''}`,
                method: 'PUT',
                body: { link },
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'shareLink', id }],
            async onQueryStarted(link, { dispatch, queryFulfilled }) {
                const patchedResult = dispatch(
                    socialApiSlice.util.updateQueryData('getShareLinks', undefined, (data) => {
                        return {
                            shareLinks: [
                                ...(data?.shareLinks || []),
                                {
                                    id: 'NEW',
                                    ownerName: 'You',
                                    ...link,
                                    createdAt: Date.now(),
                                    updatedAt: Date.now(),
                                },
                            ],
                        }
                    })
                )
                queryFulfilled.catch(patchedResult.undo)
            },
        }),
        removeShareLink: builder.mutation<void, string>({
            query: (id) => ({
                url: `social/share/link/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{ type: 'shareLink', id }],
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                const patchedResult = dispatch(
                    socialApiSlice.util.updateQueryData('getShareLinks', undefined, (data) => {
                        return {
                            shareLinks: (data?.shareLinks || []).filter((l) => l.id !== id),
                        }
                    })
                )
                queryFulfilled.catch(patchedResult.undo)
            },
        }),
    }),
})

export const {
    useGetMobileQuery,
    useGetFriendsQuery,
    useGetFriendRequestsQuery,
    useGetProfileQuery,
    usePutProfileMutation,
    useGetFriendProfileQuery,
    useGetShareLinksQuery,
    useGetShareLinkSettingsQuery,
    usePutShareLinkMutation,
    useRemoveShareLinkMutation,
    useSendMessagesMutation,
    useAcceptFriendRequestMutation,
    useRejectFriendRequestMutation,
} = socialApiSlice

// export const selectUsersResult = extendedApiSlice.endpoints.getUsers.select();
