export interface DisplayOptions {
    hideCompleted: boolean
    sortBy: string | 'progress' | 'createdAt' | 'dateStart'
    ascending: boolean
    showProgress: boolean
    hideSubtasks: boolean
    showCategoryNames: boolean
    showDateFromNowInBullet: boolean
    hideTodaysList: boolean
    inPlanningMode: boolean
}

export type MonthLayout = 'full' | 'half' | 'week'
export const isMonthLayout = (value: any): value is MonthLayout => ['full', 'half', 'week'].includes(value)

export const DEFAULT_DISPLAY_OPTIONS: DisplayOptions = {
    hideCompleted: false,
    sortBy: 'createdAt',
    ascending: true,
    showProgress: true,
    hideSubtasks: false,
    showCategoryNames: true,
    showDateFromNowInBullet: true,
    hideTodaysList: false,
    inPlanningMode: false,
    // sortByFn: (item)=> item['createdAt'],
}
