import { FeatureEnum } from '@/features/game/levels/config/types'
import { Plugin } from '@/types'
import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'
import { socialApiSlice } from '../api/socialSlice'

type State = { featureAccessMap: Record<FeatureEnum, boolean>; plugins: Record<Plugin, boolean> }

const initialState: State = {
    featureAccessMap: {
        'i.task-difficulty': true,
        'db-template': true,
        'planning-mode': true,
        'i.task-estimated-time': true,
        kanban: false,
        'snack-list': false,
        'daily-list': true,
        'capture-list': false,
        chronotype: false,
        'power-hours': false,
        'syllabus-parser': true,
        'ubc-courses-integration': false,
        goals: false,
        'smart-goals': false,
        'drag-task-into-calendar': true,
    },
    plugins: {
        alpha: false,
        student: false,
        beta: false,
        ubc: false,
        mobileApp: false,
    },
}

const levelSlice = createSlice({
    name: 'feature-access',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(apiSlice.endpoints.getUser.matchFulfilled, (state, action) => {
            // pretend this field and this payload data exist for sake of example
            const { level, plugins } = action.payload
            if (plugins) {
                for (const p of plugins) {
                    state.plugins[p] = true
                }
            }
        })

        builder.addMatcher(socialApiSlice.endpoints.getMobile.matchFulfilled, (state, action) => {
            // pretend this field and this payload data exist for sake of example
            const { isPaired } = action.payload
            state.plugins['mobileApp'] = isPaired
        })
    },
})

export const {} = levelSlice.actions
export default levelSlice.reducer
